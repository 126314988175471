import React from 'react'

const AboutAs = () => {
  return (
    <div className=' '>
     <h1 className=' justify-center text-center font-bold text-6xl mb-4 max-md:font-bold max-md:text-lg'>About VHomes.</h1>
     <p className=' text-2xl font-normal ml-64 mr-64 mb-3 max-md:font-normal max-md:text-xs max-md:m-0 max-md:mb-4 '>For years, millions of home shoppers have turned to VHomes. to find their dream home. Operated by Move, Inc., VHomes.® offers a comprehensive list of for-sale properties, as well as the information and tools to make informed real estate decisions. Today, more than ever, VHomes. is The Home of Home Search℠.

</p>
<p className=' text-2xl font-normal ml-64 mr-64 mb-4  max-md:font-normal max-md:mb-4 max-md:text-xs max-md:m-0'>VHomes. also offers homeowners a bevy of useful tools and resources through the My Home℠ dashboard. My Home℠ dashboard allows property owners to manage their home like the important investment it is by tracking their home’s value over time, researching and managing home improvements, and scouting other similar properties in the neighborhood.
</p>
    </div>
  )
}

export default AboutAs
